import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import th from 'element-plus/es/locale/lang/th'
//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import "@/core/plugins/prismjs";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import CKEditor from '@ckeditor/ckeditor5-vue';
const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA16bQIy7JasSkS4yYxm9FC8IrpuJ8pphA"
  }
})
app.use(ElementPlus, {
  locale: th,
});
// eslint-disable-next-line vue/multi-word-component-names
app.component('Datepicker', Datepicker);
// app.component('QuillEditor', QuillEditor);
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.use( CKEditor )
app.mount("#app");
