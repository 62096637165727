import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
// import jwt from 'vue-jwt-decode'
import qs from "qs";
import axios from "axios";
// import { useRouter } from "vue-router";
// const router = useRouter();
// export interface User {
//   name: string;
//   surname: string;
//   email: string;
//   password: string;
//   api_token: string;
// }

export interface UserAuthInfo {
  errors: unknown;
  user: unknown;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {};
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  get currentUser(): Object {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }
  @Action
  [Actions.GET_USER]() {
    return this.user;
  }
  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    localStorage.setItem("u_id", user.id)
    if (user.role != null) {
      if (user.role.adminGroups.length > 0) {
        localStorage.setItem("groupId", user.role.adminGroups.toString())
      }
      if (user.role.technicianGroups.length > 0) {
        localStorage.setItem("cgroupId", user.role.technicianGroups.toString())
      }
    }
    localStorage.setItem("userInfo", JSON.stringify(user))
    this.user = user;
    this.errors = {};
    JwtService.saveToken(user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  // @Mutation
  // [Mutations.SET_PASSWORD](password) {
  //   this.user.password = password;
  // }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} ;
    this.errors = [];
    localStorage.clear()
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    /* eslint-disable */
    return ApiService.post("loginAdmin", credentials ? qs.stringify({ username: credentials.username, password: credentials.password })
      : credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data.data.profile);
        this.context.commit(Mutations.SET_USER, data.data.profile);
      })
      .catch(({ error }) => {
        this.context.commit(Mutations.SET_ERROR, ["เข้าสู่ระบบไม่สำเร็จ"]);
      });
    /* eslint-disable */
  }
  @Action
  [Actions.CLEARCACHE]() {
    /* eslint-disable */
    return axios.get("clearCache", {
      headers: { token: localStorage.getItem("id_token") },
    })
      .then(({ data }) => {
      })
      .catch(({ error }) => {
      });
    /* eslint-disable */
  }
  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgetPassword", payload ? qs.stringify({ email: payload.email })
      : payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (localStorage.getItem("u_id") != null && localStorage.getItem("userInfo") != null ) {
      axios.get(process.env.VUE_APP_API_URL + "/getProfile?id=" + localStorage.getItem("u_id"), {
        headers: { token: localStorage.getItem("id_token") },
      }).then(({ data }) => {
        this.context.commit(Mutations.SET_USER, data.data);
      })
        .catch(({ error }) => {
          this.context.commit(Mutations.SET_ERROR, ["เซสชั่นหมดอายุ"]);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.SET_ERROR, ["เซสชั่นหมดอายุ"]);
      this.context.commit(Mutations.PURGE_AUTH);
    }
    // if (JwtService.getToken()) {
    //   const jwtPayload = jwt.decode(JwtService.getToken());
    //   if (jwtPayload.exp < Date.now() / 1000) {
    //     this.context.commit(Mutations.SET_ERROR, "เซสชั่นหมดอายุ");
    //     this.context.commit(Mutations.PURGE_AUTH);
    //   }
    // } else {
    //   this.context.commit(Mutations.PURGE_AUTH);
    // }
  }
}
